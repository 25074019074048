import { gql } from "@apollo/client";

const sharedTransactionFields = `
  _id
  friendlyId
  createdAt
  transactionAmount
  transactionDirection
  transactionStatus
  transactionType
  completionDate
  referenceAsset
  sender {
    ... on AccountAsset {
      _id
      assetName
      product {
        ... on DafProduct {
          _id
          name
          users {
            _id
            associationType
            user {
              ... on DonorUser {
                _id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
    ... on OrganizationEntity {
      _id
      entityName
    }
    ... on InternalAccountAsset {
      _id
      assetName
    }
    ... on DonorUser {
      _id
      firstName
      lastName
    }
    ... on StubEntity {
      _id
      entityName
    }
    ... on EquitySourceAsset {
      _id
      assetName
    }
    ... on EquityAsset {
      _id
      assetName
    }
  }
  referenceProduct {
    ... on DafProduct {
      _id
      name
      users {
        _id
        user {
          ... on DonorUser {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
  recipient {
    ... on OrganizationEntity {
      _id
      entityName
      approvedForGranting
      ein
      address {
        address1
        address2
        city
        country
        state
        zip
      }
    }
    ... on AccountAsset {
      _id
      assetName
    }
    ... on StubEntity {
      _id
      entityName
    }
    ... on EquitySourceAsset {
      _id
      assetName
    }
    ... on EquityAsset {
      _id
      assetName
    }
  }
`;

const PRODUCT_TRANSACTIONS = gql`
  query ProductTransactions($productId: ID, $transactionType: TransactionType) {
    productTransactions(productId: $productId, transactionType: $transactionType) {
      ... on GrantTransaction {
        ${sharedTransactionFields}
        acknowledgement {
          acknowledgementType
          address {
            address1
            address2
            city
            country
            state
            zip
          }
          customAcknowledgement
          name
          use
        }
        recipientCollection
        cancellationDate
        cancellationReason
        submittedDate
        paymentReceived
        checkNumber
        paymentSent
        # referenceAsset
        # requestedByUserId
        # senderCollection
      }
      ... on CashContributionTransaction {
        ${sharedTransactionFields}
        completedTransfer
        complianceType
        donationDate
        paymentMethod
        recipientCollection
        updatedAt
        settledInClearing
        requestedTransferFromClearing
        # referenceAsset
        # requestedByUserId
        # senderCollection
      }
      ... on StockContributionTransaction {
        donationDate
        stocks {
          fairMarketValue
          high
          low
          quantity
          ticker
        }
        ${sharedTransactionFields}
      }
      ... on CryptoContributionTransaction {
        ${sharedTransactionFields}
      }
      ... on EquityInvestmentTransaction {
        liquidationRequestSent
        equityType
        equityAmount
        documents {
          _id
          fileCategory
          executionDate
          createdAt
        }
        ${sharedTransactionFields}
      }
      ... on LendingInvestmentTransaction {
        ${sharedTransactionFields}
      }
      ... on FundInvestmentTransaction {
        ${sharedTransactionFields}
      }
      ... on EquityContributionTransaction {
        donationDate
        equityType
        equityAmount
        acknowledgement {
          use
        }
        documents {
          _id
          fileCategory
          executionDate
          createdAt
        }
        ${sharedTransactionFields}
      }
      ... on EquityExpenseTransaction {
        liquidationRequestSent
        ${sharedTransactionFields}
        acknowledgement {
          use
        }
      }
      ... on EquityReturnTransaction {
        settledInClearing
        acknowledgement {
          use
        }
        ${sharedTransactionFields}
      }
      ... on EquitySaleTransaction {
        acknowledgement {
          use
        }
        ${sharedTransactionFields}
      }
      ... on EquitySaleProceedsTransaction {
        dateReceived
        settledInClearing
        paymentMethod
        acknowledgement {
          use
        }
        ${sharedTransactionFields}
      }
      ... on FundDistributionTransaction {
        ${sharedTransactionFields}
      }
      ... on LendingInvestmentTransaction {
        liquidationRequestSent
        acknowledgement {
          use
        }
        ${sharedTransactionFields}
      }
      ... on LendingReturnTransaction {
        principal
        interest
        settledInClearing
        acknowledgement {
          use
        }
        recipient {
          ... on InternalAccountAsset {
            assetName
            institution
            mask
          }
        }
        ${sharedTransactionFields}
      }
      ... on RecoverableGrantTransaction {
        ${sharedTransactionFields}
      }
    }
  }
`;

const FIND_ALL_PENDING_GRANTS = gql`
  query FindAllPendingGrants {
    findAllPendingGrants {
      _id
      createdAt
      submittedDate
      transactionAmount
      checkNumber
      generatedGrantMemo
      paymentReceived
      paymentMethod
      liquidationRequestSent
      completionDate
      acknowledgement {
        acknowledgementType
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        customAcknowledgement
        name
        use
      }
      recipient {
        ... on OrganizationEntity {
          _id
          entityName
          ein
        }
      }
      sender {
        ... on AccountAsset {
          _id
          assetName
          accountLogin
        }
      }
      referenceProduct {
        ... on DafProduct {
          _id
          name
          users {
            _id
            user {
              ... on DonorUser {
                _id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_FLOATING_GRANTS = gql`
  query FindAllFloatingGrants {
    findAllFloatingGrants {
      _id
      createdAt
      submittedDate
      transactionAmount
      transactionStatus
      checkNumber
      generatedGrantMemo
      paymentReceived
      paymentMethod
      liquidationRequestSent
      completionDate
      acknowledgement {
        acknowledgementType
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        customAcknowledgement
        name
        use
      }
      recipient {
        ... on OrganizationEntity {
          _id
          entityName
          ein
        }
      }
      sender {
        ... on AccountAsset {
          _id
          assetName
          accountLogin
        }
      }
      referenceProduct {
        ... on DafProduct {
          _id
          name
          users {
            _id
            user {
              ... on DonorUser {
                _id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_IN_PROGRESS_GRANTS = gql`
  query FindAllInProgressGrants {
    findAllInProgressGrants {
      _id
      completionDate
      checkNumber
      submittedDate
      transactionAmount
      acknowledgement {
        acknowledgementType
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        customAcknowledgement
        name
        use
      }
      recipient {
        ... on OrganizationEntity {
          _id
          entityName
        }
      }
      sender {
        ... on AccountAsset {
          _id
          assetName
          product {
            ... on DafProduct {
              _id
              name
              users {
                _id
                associationType
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_INCOMING_TRANSACTIONS = gql`
  query FindAllIncomingTransactions {
    findAllIncomingTransactions {
      ... on CashContributionTransaction {
        _id
        referenceProduct {
          ... on DafProduct {
            _id
            name
          }
        }
        completionDate
        donationDate
        sender {
          ... on DonorUser {
            _id
            firstName
            lastName
          }
        }
        senderCollection
        settledInClearing
        requestedTransferFromClearing
        transactionAmount
        transactionType
        recipientCollection
        recipient {
          ... on AccountAsset {
            _id
            assetName
            product {
              ... on DafProduct {
                _id
                name
              }
            }
          }
        }
      }
      ... on StockContributionTransaction {
        _id
        completionDate
        createdAt
        donationDate
        fairMarketValue
        initializationDate
        referenceProduct {
          ... on DafProduct {
            _id
            name
          }
        }
        transactionAmount
        transactionStatus
        transactionType
        recipient {
          ... on AccountAsset {
            _id
            assetName
            product {
              ... on DafProduct {
                _id
                name
              }
            }
          }
        }
        sender {
          ... on DonorUser {
            _id
            firstName
            lastName
          }
        }
      }
      ... on EquitySaleProceedsTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        dateReceived
        settledInClearing
        requestedTransferFromClearing
        referenceProduct {
          ... on DafProduct {
            _id
            name
          }
        }
        recipient {
          ... on AccountAsset {
            _id
            product {
              ... on DafProduct {
                _id
                name
                users {
                  _id
                  associationType
                  user {
                    ... on DonorUser {
                      _id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on LendingReturnTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        settledInClearing
        requestedTransferFromClearing
        referenceProduct {
          ... on DafProduct {
            _id
            name
          }
        }
        recipient {
          ... on AccountAsset {
            _id
            product {
              ... on DafProduct {
                _id
                name
                users {
                  _id
                  associationType
                  user {
                    ... on DonorUser {
                      _id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
        sender {
          ... on OrganizationEntity {
            _id
            entityName
          }
          ... on EquitySourceAsset {
            _id
            assetName
          }
        }
      }
      ... on EquityReturnTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        settledInClearing
        requestedTransferFromClearing
        referenceProduct {
          ... on DafProduct {
            _id
            name
          }
        }
        recipient {
          ... on AccountAsset {
            _id
            product {
              ... on DafProduct {
                _id
                name
                users {
                  _id
                  associationType
                  user {
                    ... on DonorUser {
                      _id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
        sender {
          ... on OrganizationEntity {
            _id
            entityName
          }
          ... on EquitySourceAsset {
            _id
            assetName
          }
        }
      }
    }
  }
`;

const FIND_ALL_OUTGOING_TRANSACTIONS = gql`
  query FindAllOutgoingTransactions {
    findAllOutgoingTransactions {
      ... on LendingInvestmentTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        liquidationRequestSent
        recipient {
          ... on OrganizationEntity {
            _id
            entityName
          }
        }
        referenceProduct {
          ... on DafProduct {
            _id
            name
            users {
              _id
              associationType
              user {
                ... on DonorUser {
                  _id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      ... on EquityInvestmentTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        liquidationRequestSent
        recipient {
          ... on OrganizationEntity {
            _id
            entityName
          }
          ... on EquitySourceAsset {
            _id
            assetName
          }
        }
        referenceProduct {
          ... on DafProduct {
            _id
            name
            users {
              _id
              associationType
              user {
                ... on DonorUser {
                  _id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      ... on EquityExpenseTransaction {
        _id
        completionDate
        createdAt
        transactionAmount
        transactionStatus
        transactionType
        recipientCollection
        liquidationRequestSent
        recipient {
          ... on OrganizationEntity {
            _id
            entityName
          }
        }
        referenceProduct {
          ... on DafProduct {
            _id
            name
            users {
              _id
              associationType
              user {
                ... on DonorUser {
                  _id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export {
  PRODUCT_TRANSACTIONS,
  FIND_ALL_PENDING_GRANTS,
  FIND_ALL_IN_PROGRESS_GRANTS,
  FIND_ALL_FLOATING_GRANTS,
  FIND_ALL_INCOMING_TRANSACTIONS,
  FIND_ALL_OUTGOING_TRANSACTIONS,
};
