import { gql } from "@apollo/client";

const QUERY_SCHEDULES = gql`
  query GetSchedulesByProductId($productId: ID!) {
    getSchedulesByProductId(productId: $productId) {
      _id
      acknowledgement {
        acknowledgementType
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        customAcknowledgement
        name
        use
      }
      endDate
      executedOccurrences
      frequency
      nextExecutionDate
      scheduledOccurrences
      startDate
      status
      cancellationReason
      cancellationDate
      meta {
        testData
      }
      transactionAmount
      recipientCollection
      recipient {
        ... on OrganizationEntity {
          _id
          ein
          entityName
          address {
            city
            country
            state
          }
        }
      }
    }
  }
`;

export { QUERY_SCHEDULES };
