import React, { useState, useEffect, createContext, lazy, Suspense, useCallback } from "react";
import Auth from "./utils/auth";
import "./App.css";
import { useLazyQuery } from "@apollo/client";
import { QUERY_USER } from "./graphQL/queries";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "./UserContext";
import CircularLoading from "./components/loading/CircularLoading";
import * as Sentry from "@sentry/react";
import LoggedInCountdownTimer from "./layout/LoggedInCountdownTimer";
import { ThemeProvider } from "@mui/material";
import useCustomTheme from "./themeConfig";
const LoggedInNav = lazy(() => import("./layout/LoggedInNav"));
const ExternalRoutes = lazy(() => import("./navigation/ExternalRoutes"));

export const UserContext = createContext();

export default function App() {
  const theme = useCustomTheme();
  var currentURL = new URL(window.location.href);
  var searchParams = new URLSearchParams(currentURL.search);
  let emailToken = searchParams.get("token");
  const { contextUserData, contextUserId, updateContextUser, setLoggedInUser, loggedInUser } =
    useUserContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [initialCountdown, setInitialCountdown] = useState(null);
  const navigate = useNavigate();
  const logoutAlertTime = 120;
  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    Auth.logout();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      if (loggedInUser) {
        Sentry.setUser({
          id: loggedInUser?._id,
        });
      } else {
        Sentry.setUser(null);
      }
    }
  }, [loggedInUser]);

  const [queryUser, { loading, data, error, refetch }] = useLazyQuery(QUERY_USER);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await queryUser();
      } catch (error) {
        console.error(error);
        throw new Error("QUERY_USER error:", error);
      }
    };

    if (Auth.loggedIn()) {
      if (emailToken) {
        Auth.logout();
      }
      if (Auth.checkEmailVerified()) {
        setIsLoggedIn(true);
        fetchUser();
      } else {
        if (isLoggedIn === true) {
          setIsLoggedIn(false);
          window.location.reload();
        }
      }
      // else {
      //   var currentURL = new URL(window.location.href);

      //   if (currentURL.pathname === "/login") {
      //     console.log("there email is not verified but they are on the login page. checking the url and attempting to validate email")
      //     validateEmail(currentURL);
      //     if (Auth.checkEmailVerified()) {
      //       console.log("their email is now verified")
      //       setIsLoggedIn(true);
      //       fetchUser();
      //     } else {
      //       console.log()
      //     }
      //   }
      // }
    } else {
      if (isLoggedIn === true) {
        setIsLoggedIn(false);
        window.location.reload();
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (data && data.user) {
      setLoggedInUser(data.user);
    }
  }, [data]);

  useEffect(() => {
    if (isLoggedIn) {
      const countdownTime = () => {
        const tokensExpiration = Auth.getTokensExpiration();
        if (tokensExpiration === false) {
          console.error("Failed to get token expiration.");
          handleLogout();
        }
        const { accessTokenExp } = tokensExpiration;
        const currentTime = Math.floor(Date.now() / 1000);
        return accessTokenExp - currentTime;
      };
      setInitialCountdown(countdownTime());
    }
  }, [isLoggedIn]);
  useEffect(() => {
    updateContextUser(contextUserId);
  }, [contextUserId]);

  function updateIsLoggedIn(newValue) {
    setIsLoggedIn(newValue);
  }

  const preventCrash = () => {
    alert(
      "There was an error displaying your account, please contact customer service at support@uicharitable.org"
    );
    handleLogout();
  };

  return isLoggedIn ? (
    loading && !data && !data?.user ? (
      <CircularLoading
        boxSx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
        loading={true}
      />
    ) : data && data.user ? (
      <ThemeProvider theme={theme}>
        <Suspense>
          {isLoggedIn && initialCountdown !== null && (
            <LoggedInCountdownTimer
              initialCountdown={initialCountdown}
              logoutAlertTime={logoutAlertTime}
              handleLogout={handleLogout}
            />
          )}
          <LoggedInNav
            userId={contextUserData ? contextUserData._id : data.user._id}
            user={contextUserData ? contextUserData : data.user}
            refetchLoggedInUser={refetch}
            loggedInUserId={data.user._id}
            onLogout={updateIsLoggedIn}
            handleLogout={handleLogout}
          />
        </Suspense>
      </ThemeProvider>
    ) : (
      <>{preventCrash()}</>
    )
  ) : (
    <ThemeProvider theme={theme}>
      <Suspense>
        <ExternalRoutes />
      </Suspense>
    </ThemeProvider>
  );
}
