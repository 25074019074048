import { gql } from "@apollo/client";

const PENDING_TASKS_FINANCE = gql`
  query pendingTasksFinance {
    pendingTasksFinance {
        grantsRequested
        grantsInTransit
        floatingGrants
        incomingTransactions
        outgoingTransactions
        newUsers
        orgReview
        docReview
    }
  }
`;

export { PENDING_TASKS_FINANCE };
