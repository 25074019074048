import { gql } from "@apollo/client";

const FEE_TRANSACTIONS = gql`
  query FeeTransactions($productId: ID, $assetId: ID, $transactionId: ID) {
    feeTransactions(productId: $productId, assetId: $assetId, transactionId: $transactionId) {
      ... on ClatAdminFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
        product {
          ... on DafProduct {
            _id
            name
          }
        }
      }
      ... on DafAdminFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on DafArrearFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on DueDiligenceFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on BrandingFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        receivedAmount
        requestedAmount
        status
        waivedAmount
      }
      ... on ExpediteFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const FEE_TRANSACTIONS_YEAR_QUARTER = gql`
  query FeeTransactionsYearQuarter($year: Int, $quarter: Int) {
    feeTransactionsYearQuarter(year: $year, quarter: $quarter) {
      ... on ClatAdminFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
        product {
          ... on DafProduct {
            _id
            name
          }
        }
      }
      ... on DafAdminFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on DafArrearFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on DueDiligenceFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
      ... on ExpediteFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        product {
          ... on DafProduct {
            _id
            name
          }
        }
        quarter
        receivedAmount
        requestedAmount
        status
        waivedAmount
        year
        users {
          _id
          associationType
          user {
            ... on InternalUser {
              _id
              firstName
              lastName
            }
            ... on DonorUser {
              _id
              firstName
              lastName
            }
            ... on FirmUser {
              _id
              firstName
              lastName
            }
            ... on EntityUser {
              _id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const FEE_TRANSACTIONS_BY_FIRM = gql`
  query FeeTransactionsByFirm($entityId: ID!, $quarter: Int!, $year: Int!) {
    feeTransactionsByFirm(entityId: $entityId, quarter: $quarter, year: $year) {
      ... on DueDiligenceFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on ExpediteFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on ClatAdminFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on DafAdminFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on DafArrearFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
    }
  }
`;

const FEE_TRANSACTIONS_WITHOUT_ADVISOR = gql`
  query FeeTransactionsByDonorWithoutAdvisor($quarter: Int!, $year: Int!) {
    feeTransactionsByDonorWithoutAdvisor(quarter: $quarter, year: $year) {
      ... on ClatAdminFeeTransaction {
        _id
      }
      ... on DafAdminFeeTransaction {
        _id
      }
      ... on DafArrearFeeTransaction {
        _id
      }
      ... on DueDiligenceFeeTransaction {
        _id
      }
      ... on ExpediteFeeTransaction {
        _id
      }
    }
  }
`;

export {
  FEE_TRANSACTIONS,
  FEE_TRANSACTIONS_YEAR_QUARTER,
  FEE_TRANSACTIONS_BY_FIRM,
  FEE_TRANSACTIONS_WITHOUT_ADVISOR,
};
