import { gql } from "@apollo/client";

const FIND_ALL_ENTITIES = gql`
  query FindAllEntities($page: Int, $pageSize: Int) {
    findAllEntities(page: $page, pageSize: $pageSize) {
      entities {
        ... on FirmEntity {
          _id
          entityName
          entityType
        }
        ... on OrganizationEntity {
          _id
          entityName
          entityType
          legalStructure
          approvedForGranting
        }
        ... on PartnerEntity {
          _id
          entityName
          entityType
        }
        ... on BranchEntity {
          _id
          entityName
          entityType
        }
      }
      pageInfo {
        currentPage
        pageSize
        totalDocuments
        totalPages
      }
    }
  }
`;

const FIND_ENTITY = gql`
  query FindEntity($entityId: ID) {
    findEntity(entityId: $entityId) {
      ... on OrganizationEntity {
        _id
        entityName
        website
        ein
        phone
        legalStructure
        approvedForGranting
        primaryEmail
        address {
          address1
          address2
          city
          country
          state
          zip
        }
      }
      ... on FirmEntity {
        _id
        entityName
        entityType
        crd
        partner {
          _id
          entityName
        }
        primaryEmail
        additionalEmails
        phone
        address {
          address1
          address2
          city
          country
          state
          zip
        }
      }
      ... on BranchEntity {
        _id
        entityName
        entityType
        partner {
          _id
          entityName
        }
        primaryEmail
        additionalEmails
        phone
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        firm {
          _id
          entityName
        }
      }
    }
  }
`;

const FIND_FIRM_USERS_OPTIMIZED = gql`
query FindUsersUnderFirmByFirmId($firmId: ID!, $isFirmUser: Boolean) {
  findUsersUnderFirmByFirmId(firmId: $firmId, isFirmUser: $isFirmUser) {
    _id
    roles
    firstName
    lastName
    type
    totals {
      aum
      cash
      stock
      crypto
      alternativeAssets
      other
    }
    advisedUsers {
      _id
      firstName
      lastName
      type
      totals {
        portfolioBalance
        cash
        stock
        crypto
        alternativeAssets
        other
      }
      products {
        _id
        associationType
        productName
      }
    }
  }
}
`;

const FIND_FIRM_USERS = gql`
  query FindFirmWithUsers($firmId: ID!) {
    findFirmWithUsers(firmId: $firmId) {
      ... on FirmEntity {
        _id
        additionalEmails
        aum
        crd
        ein
        entityName
        entityType
        friendlyId
        lastUpdated
        notes
        numberOfDonors
        phone
        primaryEmail
        source
        createdAt
        totalAccountsAdvised
        users {
          _id
          associationType
          createdAt
          meta {
            testData
          }
          updatedAt
          user {
            ... on FirmUser {
              _id
              emailVerified
              firstName
              lastName
              roles
              type
              totals {
                aum
                cash
                stock
                alternativeAssets
                other
              }
              advisedUsers {
                _id
                emailVerified
                firstName
                lastName
                roles
                type
                totals {
                  portfolioBalance
                  cash
                  stock
                  alternativeAssets
                  other
                }
                products {
                  _id
                  associationType
                  product {
                    ... on DafProduct {
                      _id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        address {
          address1
          address2
          city
          country
          state
          zip
        }
      }
      ... on BranchEntity {
        _id
        additionalEmails
        ein
        entityName
        entityType
        friendlyId
        lastUpdated
        notes
        phone
        primaryEmail
        source
        createdAt
        users {
          _id
          associationType
          createdAt
          meta {
            testData
          }
          updatedAt
          user {
            ... on FirmUser {
              _id
              emailVerified
              firstName
              lastName
              roles
              type
              totals {
                aum
                cash
                stock
                alternativeAssets
                other
              }
              advisedUsers {
                _id
                emailVerified
                firstName
                lastName
                roles
                type
                totals {
                  portfolioBalance
                  cash
                  stock
                  alternativeAssets
                  other
                }
                products {
                  _id
                  associationType
                  product {
                    ... on DafProduct {
                      _id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        address {
          address1
          address2
          city
          country
          state
          zip
        }
      }
    }
  }
`;

const FIND_FIRM_ENTITIES = gql`
  query FindFirmEntities($entityType: EntityTypeEnum) {
    findFirmEntities(entityType: $entityType) {
      ... on BranchEntity {
        _id
        entityName
        entityType
        primaryEmail
        address {
          city
          state
        }
        firm {
          _id
          crd
        }
      }
      ... on FirmEntity {
        _id
        entityName
        entityType
        crd
        primaryEmail
        address {
          city
          state
        }
      }
    }
  }
`;

const FIND_ADVISORS_BY_FIRM_ID = gql`
  query FindAllAdvisorsByFirmId($firmId: ID!) {
    findAllAdvisorsByFirmId(firmId: $firmId) {
      _id
      firstName
      lastName
    }
  }
`;

const FIND_NON_IRS_ORGS = gql`
  query FindNonIrsOrgs {
    findNonIrsOrgs {
      ... on OrganizationEntity {
        _id
        entityName
        entityType
        legalStructure
        approvedForGranting
        primaryEmail
        address {
          city
          state
        }
      }
    }
  }
`;

const FIND_ORGANIZATION_FOR_REVIEW = gql`
  query FindOrganizationForReview($entityId: ID) {
    findOrganizationForReview(entityId: $entityId) {
      ... on OrganizationEntity {
        _id
        entityName
        website
        ein
        phone
        legalStructure
        approvedForGranting
        primaryEmail
        address {
          address1
          address2
          city
          country
          state
          zip
        }
        entityType
        meta {
          reason
          requestedByUserId {
            _id
            firstName
            lastName
            email
            roles
            type
          }
        }
      }
    }
  }
`;

const FIND_ALL_ORGANIZATIONS_FOR_REVIEW = gql`
  query FindAllOrganizationsForReview {
    findAllOrganizationsForReview {
      ... on OrganizationEntity {
        _id
        entityName
        entityType
        legalStructure
        primaryEmail
        address {
          city
          state
        }
      }
    }
  }
`;

const FIND_ALL_PARTNERS = gql`
  query FindAllPartners {
    findAllPartners {
      _id
      entityName
    }
  }
`;

const FIND_ALL_ORGANIZATIONS = gql`
  query FindAllOrganizations($search: String) {
    findAllOrganizations(search: $search) {
      _id
      entityName
    }
  }
`;

const FIND_FIRM_INVITES = gql`
query FindInvitesByFirmId($firmId: ID!) {
  findInvitesByFirmId(firmId: $firmId) {
    _id
    senderId
    expectedFirstName
    expectedLastName
    expectedEmail
    inviteStatus
    inviteExpirationDate
    inviteConfirmationToken
    inviteType
    createdAt
    updatedAt
    linkedField
    linkedDocumentId {
      entityName
    }
    type
    roles
  }
}
`

const FIND_CHILD_BRANCHES = gql`
query FindChildBranches($firmId: ID) {
  findChildBranches(firmId: $firmId) {
    _id
    entityName
  }
}
`

export {
  FIND_ALL_ENTITIES,
  FIND_ENTITY,
  FIND_FIRM_USERS,
  FIND_FIRM_ENTITIES,
  FIND_ADVISORS_BY_FIRM_ID,
  FIND_NON_IRS_ORGS,
  FIND_ALL_PARTNERS,
  FIND_ALL_ORGANIZATIONS,
  FIND_ORGANIZATION_FOR_REVIEW,
  FIND_ALL_ORGANIZATIONS_FOR_REVIEW,
  FIND_FIRM_USERS_OPTIMIZED,
  FIND_FIRM_INVITES,
  FIND_CHILD_BRANCHES
};
