import { gql } from "@apollo/client";

const sharedUserFields = `
... on InternalUser {
  _id
  firstName
  lastName
}
... on DonorUser {
  _id
  firstName
  lastName
}
... on FirmUser {
  _id
  firstName
  lastName
}
... on EntityUser {
  _id
  firstName
  lastName
}
`;

const sharedProductFields = `
... on DafProduct {
  _id
  name
  users {
    _id
    associationType
    user {
      ${sharedUserFields}
    }
  }
}
... on ClatProduct {
  _id
  name
  users {
    _id
    associationType
    user {
      ${sharedUserFields}
    }
  }
}
... on BrandingProduct {
  _id
  name
  users {
    _id
    associationType
    user {
      ${sharedUserFields}
    }
  }
}
`;

const FIND_ASSET = gql`
  query FindAsset($assetId: ID!) {
    findAsset(assetId: $assetId) {
      ... on AccountAsset {
        accountNumber
      }
    }
  }
`;

const FIND_PLAID_LINKED_ASSETS = gql`
  query FindPlaidLinkedAssets($accountLogin: String, $plaidAccessToken: ID) {
    findPlaidLinkedAssets(accountLogin: $accountLogin, plaidAccessToken: $plaidAccessToken) {
      _id
      accountNumber
      plaidAccountId
      plaidAccessToken
      totalHoldings {
        accountCashHoldings
        accountStockHoldings
      }
      meta {
        isDeleted
        plaidUpdateSuccessful
      }
    }
  }
`;

const FIND_ALL_ACCOUNT_ASSETS = gql`
  query FindAllAccountAssets {
    findAllAccountAssets {
      assets {
        ... on AccountAsset {
          _id
          assetName
          assetType
          accountLogin
          accountNumber
          institution
          totalHoldings {
            accountCashHoldings
            accountStockHoldings
            accountCryptoHoldings
            date
          }
          product {
            ... on DafProduct {
              _id
              name
              users {
                _id
                associationType
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on ClatProduct {
              _id
              name
              users {
                _id
                associationType
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_LENDING_ASSETS = gql`
  query LendingAsset {
    findAllLendingAssets {
      assets {
        ... on LendingAsset {
          _id
          assetName
          assetType
          loanAmount
          totalHoldings {
            interestReceived
            outstandingBalance
            principalReceived
            totalReceived
          }
          product {
            ... on DafProduct {
              _id
              name
              users {
                _id
                associationType
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_EQUITY_ASSETS = gql`
  query FindAllEquityAssets {
    findAllEquityAssets {
      assets {
        ... on EquityAsset {
          _id
          assetName
          assetType
          product {
            ... on DafProduct {
              _id
              name
              users {
                _id
                associationType
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
          acquisitionMethod
          appraisalDate
          appraisalReceived
          assetStartDate
          assetPendingTransactionTotal
          committedCapital
          createdAt
          form8283Signed
          hasCommittedCapital
          lastUpdated {
            date
          }
          saleDocsReceived
          totalHoldings {
            capitalCalledToDate
            date
            marketValue
            manualCalculation
          }
          updatedAt
        }
      }
    }
  }
`;

const FIND_ALL_PENDING_EQUITY_DONATIONS = gql`
  query FindAllPendingEquityDonations {
    findAllPendingEquityDonations {
      asset {
        _id
        assetName
        status
        totalHoldings {
          date
          marketValue
        }
        meta {
          reminders {
            reminderType
            reminderRecipient
            reminderDate
          }
        }
        product {
          ... on DafProduct {
            _id
            name
            users {
              _id
              associationType
              user {
                ... on DonorUser {
                  _id
                  firstName
                  lastName
                  email
                  advisor {
                    _id
                    firstName
                    lastName
                    email
                  }
                }
              }
            }
          }
        }
        documents {
          _id
          createdAt
          fileCategory
        }
      }
      transactions {
        ... on EquityContributionTransaction {
          _id
          donationDate
          transactionAmount
          transactionStatus
          transactionType
          documents {
            _id
            createdAt
            fileCategory
          }
          meta {
            reminders {
              reminderType
              reminderRecipient
              reminderDate
            }
          }
        }
        ... on EquitySaleTransaction {
          _id
          transactionAmount
          transactionStatus
          transactionType
          documents {
            _id
            createdAt
            fileCategory
          }
        }
        ... on EquitySaleProceedsTransaction {
          _id
          transactionAmount
          transactionStatus
          transactionType
          documents {
            _id
            createdAt
            fileCategory
          }
        }
      }
      count {
        appraisal
        equityContribution
        equitySale
        equitySaleProceeds
        form8282
        form8283
        saleDocument
      }
    }
  }
`;

const FIND_NON_UPDATED_ACCOUNT_ASSETS = gql`
  query FindNonUpdatedAccountAssets {
    findNonUpdatedAccountAssets {
      assets {
        ... on AccountAsset {
          _id
          assetName
          accountLogin
          accountNumber
          assetType
          institution
          totalHoldings {
            accountCashHoldings
            accountStockHoldings
            accountCryptoHoldings
            date
          }
          product {
            ... on DafProduct {
              _id
              name
              users {
                _id
                user {
                  ... on DonorUser {
                    _id
                    firstName
                    lastName
                  }
                }
                associationType
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_ALL_ASSETS = gql`
query FindAllAssets($page: Int, $pageSize: Int) {
  findAllAssets(page: $page, pageSize: $pageSize) {
    assets {
      ... on AccountAsset {
        _id
        assetName
        assetType
        institution
        accountLogin
        accountNumber
        lastUpdated {
          date
          userId
        }
        updatedAt
        totalHoldings {
          accountCashHoldings
          accountStockHoldings
          accountCryptoHoldings
          date
        }
        product {
          ${sharedProductFields}
        }
      }
      ... on EquityAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
      ... on FundAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
      ... on LendingAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
      ... on OtherAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
      ... on RecoverableGrantAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
      ... on InternalAccountAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
        product {
          ${sharedProductFields}
        }
      }
    }
  }
}
`;

const FIND_ACCOUNT_NUMBERS_BY_PRODUCT_ID = gql`
  query FindAccountNumbersByProductId($productId: ID!) {
    findAccountNumbersByProductId(productId: $productId) {
      _id
      accountNumber
    }
  }
`;

const FIND_ASSETS_BY_PRODUCT_ID = gql`
  query FindAssetsByProductId($productId: ID!) {
    findAssetsByProductId(productId: $productId) {
      ... on AccountAsset {
        _id
        assetName
        assetType
        institution
        accountLogin
        accountNumber
        lastUpdated {
          date
          userId
        }
        updatedAt
        totalHoldings {
          accountCashHoldings
          accountStockHoldings
          accountCryptoHoldings
          date
        }
      }
      ... on EquityAsset {
        _id
        assetName
        assetType
        hasCommittedCapital
        committedCapital
        appraisalDate
        form8283Signed
        saleDocsReceived
        acquisitionMethod
        sourceCollection
        source {
          ... on OrganizationEntity {
            _id
          }
          ... on EquitySourceAsset {
            _id
          }
        }
        totalHoldings {
          date
          marketValue
          manualCalculation
          capitalCalledToDate
        }
      }
      ... on FundAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
      }
      ... on LendingAsset {
        _id
        assetName
        assetType
        loanAmount
        referenceEntity {
          _id
        }
        totalHoldings {
          date
          principalReceived
          interestReceived
          totalReceived
          outstandingBalance
        }
        updatedAt
      }
      ... on OtherAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
      }
      ... on RecoverableGrantAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
      }
      ... on InternalAccountAsset {
        _id
        assetName
        assetType
        lastUpdated {
          date
          userId
        }
        updatedAt
      }
    }
  }
`;

const FIND_ASSETS_WITH_DOCUMENTS_BY_PRODUCT_ID = gql`
  query FindAssetsWithDocumentsByProductId($productId: ID!) {
    findAssetsWithDocumentsByProductId(productId: $productId) {
      ... on EquityAsset {
        _id
        assetName
        documents {
          _id
          createdAt
          executionDate
          fileCategory
          status
          signedBy
          fileName
          s3Key
        }
      }
      ... on LendingAsset {
        _id
        assetName
        documents {
          _id
          createdAt
          executionDate
          fileCategory
          status
          signedBy
          fileName
          s3Key
        }
      }
    }
  }
`;

const FIND_ASSETS_BY_TYPE = gql`
  query FindAssetsByType($assetType: AssetType!) {
    findAssetsByType(assetType: $assetType) {
      ... on EquitySourceAsset {
        _id
        assetName
      }
    }
  }
`;

const RUN_DUNHAM_HOLDINGS = gql`
  query RunDunhamHoldings {
    runDunhamHoldings {
      ... on AccountAsset {
        _id
        accountLogin
        accountNumber
        assetName
        institution
        totalHoldings {
          accountCashHoldings
          accountStockHoldings
        }
      }
    }
  }
`;

const GET_CSV_HOLDINGS_STATUS = gql`
  query GetCsvHoldingsStatus($institution: String) {
    getCsvHoldingsStatus(institution: $institution) {
      totalCsvAssets
      totalOutOfDateAssets
      status
      oldestUpdate
      assets {
        _id
        accountNumber
        updateSuccessful
        totalHoldings {
          date
          accountCashHoldings
          accountStockHoldings
          accountTotalHoldings
        }
      }
    }
  }
`;

const RUN_FIDELITY_HOLDINGS = gql`
  query RunFidelityHoldings {
    runFidelityHoldings {
      ... on AccountAsset {
        _id
      }
    }
  }
`;

const RUN_FIDELITY_HOLDINGS_CSV = gql`
  query Query {
    runFidelityHoldingsCsv
  }
`;

export {
  FIND_ASSET,
  FIND_PLAID_LINKED_ASSETS,
  FIND_ALL_LENDING_ASSETS,
  FIND_ALL_EQUITY_ASSETS,
  FIND_ALL_PENDING_EQUITY_DONATIONS,
  FIND_ALL_ACCOUNT_ASSETS,
  FIND_ACCOUNT_NUMBERS_BY_PRODUCT_ID,
  FIND_NON_UPDATED_ACCOUNT_ASSETS,
  FIND_ALL_ASSETS,
  FIND_ASSETS_BY_PRODUCT_ID,
  FIND_ASSETS_WITH_DOCUMENTS_BY_PRODUCT_ID,
  RUN_DUNHAM_HOLDINGS,
  FIND_ASSETS_BY_TYPE,
  GET_CSV_HOLDINGS_STATUS,
  RUN_FIDELITY_HOLDINGS,
  RUN_FIDELITY_HOLDINGS_CSV
};
