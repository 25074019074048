import { gql } from "@apollo/client";

const QUERY_CHARITY = gql`
  query CharitySearch($search: String) {
    charitySearch(search: $search) {
      _id
      friendlyId
      address {
        city
        country
        state
      }
      entityName
      entityType
      charityType
      ein
      highlights
      score
    }
  }
`;

export { QUERY_CHARITY };