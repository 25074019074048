import { createTheme } from "@mui/material";
import { useBrandingContext } from "./BrandingContext";

const useCustomTheme = () => {
  const { loading, error, branding } = useBrandingContext();

  let primaryMain = "#FFFFFF"
  let primaryHover = "#FFFFFF"
  let primaryOpposite = "#FFFFFF"
  let primaryOppositeHover = "#FFFFFF"
  let secondaryMain = "#FFFFFF"
  let secondaryHover = "#FFFFFF"
  let tertiaryMain = "#FFFFFF"
  let tertiaryHover = "#FFFFFF"
  let accent1 = "#EFEFEF";
  let accent2 = "#A5A5A5";
  let primaryGreen = "#61AF2E";

  switch (window.location.hostname) {
    case "portal.uicharitable.org":
      primaryMain = "#000F59"; // "#100E0D" old black
      primaryHover = "rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
      secondaryMain = "#FFB806";
      secondaryHover = "rgba(255, 184, 6, 0.75)";
      tertiaryMain = "#0022CC";
      tertiaryHover = "rgba(1, 34, 204, 0.75";
      break;
    default:
      if (branding) {
        primaryMain = `rgb(${branding?.primaryColor?.join(",")})`; //"#000F59"; // "#100E0D" old black
        primaryHover = `rgba(${branding?.primaryColor?.join(",")}, 0.75)`; //"rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
        secondaryMain = `rgb(${branding?.secondaryColor?.join(",")})`; //"#FFB806";
        secondaryHover = `rgba(${branding?.secondaryColor?.join(",")}, 0.75)`; //"rgba(255, 184, 6, 0.75)";
        tertiaryMain = branding?.tertiaryColor?.length > 0 ? `rgb(${branding?.tertiaryColor?.join(",")})` : "#0022CC"; //"#0022CC";
        tertiaryHover = branding?.tertiaryColor?.length > 0 ? `rgba(${branding?.tertiaryColor?.join(",")}, 0.75)` : "rgba(1, 34, 204, 0.75";
      } else if (!branding && !loading) {
        primaryMain = "#000F59"; // "#100E0D" old black
        primaryHover = "rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
        secondaryMain = "#FFB806";
        secondaryHover = "rgba(255, 184, 6, 0.75)";
        tertiaryMain = "#0022CC";
        tertiaryHover = "rgba(1, 34, 204, 0.75";
      }
      break;
  }
  return createTheme({
    typography: {
      fontFamily: "Figtree", // Apply the Figtree font to all text in the theme
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: "h1" },
            style: {
              color: "black",
              fontWeight: 300,
              fontSize: "96px",
            },
          },
          {
            props: { variant: "h2" },
            style: {
              color: "black",
              fontWeight: 300,
              fontSize: "60px",
            },
          },
          {
            props: { variant: "h3" },
            style: {
              color: "black",
              fontWeight: 700,
              fontSize: "48px",
            },
          },
          {
            props: { variant: "h4" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "34px",
            },
          },
          {
            props: { variant: "h5" },
            style: {
              color: "black",
              fontWeight: 700,
              fontSize: "24px",
            },
          },
          {
            props: { variant: "h6" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "20px",
            },
          },
          {
            props: { variant: "subtitle1" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "16px",
            },
          },
          {
            props: { variant: "subtitle2" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "14px",
            },
          },
          {
            props: { variant: "body1" },
            style: {
              color: "black",
              fontWeight: 700,
              fontSize: "16px",
            },
          },
          {
            props: { variant: "body2" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "16px",
            },
          },
          {
            props: { variant: "body3" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "14px",
            },
          },
          {
            props: { variant: "caption" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "12px",
            },
          },
          {
            props: { variant: "overline" },
            style: {
              color: "black",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              textTransform: "uppercase",
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "Button1" },
            style: {
              backgroundColor: primaryMain,
              color: "white",
              border: "2px solid",
              borderColor: primaryMain,
              borderRadius: "10px 0px",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              fontWeight: "bold",
              ":hover": {
                backgroundColor: "white",
                color: primaryMain,
              },
              ":disabled": {
                color: "white",
              },
            },
          },
          {
            props: { variant: "Button2" },
            style: {
              backgroundColor: secondaryMain,
              color: primaryMain,
              border: "2px solid",
              borderColor: secondaryMain,
              borderRadius: "10px 0px",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              fontWeight: "bold",
              ":hover": {
                backgroundColor: "white",
                color: primaryMain,
              },
            },
          },
          {
            props: { variant: "Button3" },
            style: {
              backgroundColor: "white",
              color: primaryMain,
              border: "2px solid",
              borderColor: "black",
              borderRadius: "10px 0px",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              fontWeight: "bold",
              ":hover": {
                backgroundColor: "primary.main",
                color: "white",
                borderColor: "white",
              },
            },
          },
          {
            props: { variant: "Button4" },
            style: {
              backgroundColor: tertiaryMain,
              color: "white",
              border: "2px solid",
              borderColor: tertiaryMain,
              borderRadius: "10px 0px",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              fontWeight: "bold",
              ":hover": {
                backgroundColor: "white",
                color: tertiaryMain,
              },
              ":disabled": {
                color: "white",
              },
            },
          },
        ],
      },
    },
    palette: {
      primary: {
        main: primaryMain,
        hover: primaryHover,
        opposite: primaryOpposite,
        oppositeHover: primaryOppositeHover,
        green: primaryGreen,
      },
      secondary: {
        main: secondaryMain,
        hover: secondaryHover,
      },
      tertiary: {
        main: tertiaryMain,
        hover: tertiaryHover,
      },
      accent1: {
        main: accent1,
      },
      accent2: {
        main: accent2,
      },
      sheets: {
        main: "#188038",
        hover: "rgba(24, 128, 56, 0.5)",
      },
      background: {
        main: "#97D0DC",
      },
    },
  });
};

export default useCustomTheme;
